import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"

const success = () => {
  return (
    <>
      <Seo title="Message received!" />
      <Layout>
        <BannerModule small={true} />
        <div className="section">
          <div className="container container__tight">
            <h1>Thanks for getting in touch!</h1>
            <p>We'll (probably) get back to you pretty soon(ish).</p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default success
